//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CardMedia,
  Card,
  // Customizable Area End
  Grid,

} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { styled } from '@mui/system';
import ImageMain from '../assets/sewamo.png';
import MinusIcon from '../assets/minusIcon.svg';
import PlusIcon from '../assets/plusIcon.svg';
import ZoomIcon from '../assets/zoomIcon.svg';
import PriceIcon from '../assets/priceIcon.svg';
import UnlockIcon from '../assets/unlockIcon.svg';
import InsFist from '../assets/inspirationSecond.png';
import InsSecond from '../assets/inspirationFirst.png';
import CertificationFirst from '../assets/certificationFirst.svg';
import CertificationSecond from '../assets/certifiSecond.svg';
import CertificationThird from '../assets/certifiThird.svg';
import productImg from '../assets/productImage.svg';
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";



import { FavoriteBorder as FavoriteBorderIcon } from '@mui/icons-material';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  }
);
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderThumbnailPart = () => {
    return (
      <Grid container >
        <ThumbnailContainer item xs={12} md={6} sm={12}>
          <div style={{
            border: '2px solid #E7E5E4',
            padding: '3px',
            marginBottom: "17px"
          }}>
            <ProductImage
              image={dummyProduct}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
          <FavoriteCompomnent>
            <FavoriteBorderIcon />
          </FavoriteCompomnent>
          <ZoomButton>
            <img
              src={ZoomIcon}
              alt="Zoom Icon" />
          </ZoomButton>
          <SmallImagesContainer container>
            <SmallImage
              item
              style={{ backgroundImage: `url(${dummyProduct})` }}
            />
            <SmallImage
              item
              style={{ backgroundImage: `url(${dummyProduct})` }}
            />
            <SmallImage
              item
              style={{ backgroundImage: `url(${dummyProduct})` }}
            />
          </SmallImagesContainer>
        </ThumbnailContainer>
        <Grid item xs={12} md={6} sm={12} style={{ marginTop: "10px" }} >
          <Typography style={{ fontSize: "16px", fontWeight: 420, color: "#A8A29E" }} gutterBottom>
            Circular Marketplace / <span style={{ color: "#292524" }}>Lion Brand Yarn</span>
          </Typography>
          <Typography style={{ fontSize: "24px", fontWeight: 420, color: "#292524" }} gutterBottom>
            Lion Brand 24/7 Cotton 186yds Worsted Ready to dye yarn 30kg bale
          </Typography>
          <Typography style={{ fontSize: "16px", fontWeight: 420 }} >
            SKU ID <span style={{ color: "#528A6F" }}>#258469987</span>
          </Typography>
          <Grid container style={{ marginTop: "5px" }} alignItems="center" spacing={2}>
            <Grid item>
              <DiscountedPrice >£4.50</DiscountedPrice>
            </Grid>
            <Grid item style={{ lineHeight: "15px" }}>
              <PriceTag>£10.00</PriceTag>
              <p style={{ margin: "0px", color: "#44403C" }}>/metre</p>
            </Grid>
            <Grid item>
              <BoxComponent>
                <Typography style={{ color: "#528A6F", fontSize: "16px", fontWeight: 420 }}>
                  55% off
                </Typography>
              </BoxComponent>
            </Grid>
          </Grid>
          <Typography style={{ color: "#162A26", fontWeight: 450, fontSize: "16px", textTransform: "uppercase" }} marginTop={2}>
            Meters required
          </Typography>
          <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#292524" }} marginTop={2}>
            MOQ: <span style={{ fontSize: "14px", fontWeight: 390 }}>10 metres</span>
          </Typography>
          <Grid style={{ paddingLeft: "16px", marginTop: "6px" }} container alignItems="center" spacing={2}>
            <MeterWrapper container alignItems="center">
              <Grid style={{ paddingLeft: "13px" }} item>
                <StyledIconButton data-test-id="decrease-test-id" onClick={this.handleDecrease}>
                  <img src={MinusIcon} />
                </StyledIconButton>
              </Grid>
              <Grid item>
                <MeterInput>{this.state.meters}</MeterInput>
              </Grid>
              <Grid item>
                <StyledIconButton data-test-id="increase-test-id" onClick={this.handleIncrease}>
                  <img src={PlusIcon} />
                </StyledIconButton>
              </Grid>
              <MeterComponent item>
                <UnitBox>Metres</UnitBox>
              </MeterComponent>
            </MeterWrapper>
            <QuantityText>QTY: {this.state.meters.toLocaleString()} metres</QuantityText>
          </Grid>
          <PriceComponent item>
            <img style={{ width: "13.1px" }} src={PriceIcon} />
            <Typography style={{ fontSize: "16px", fontWeight: 420, color: "#292524", margin: "13px 0px 0px" }} marginTop={2}>
              Price Summary: £{(4.5 * this.state.meters).toFixed(2)} / {this.state.meters} metres
            </Typography>
          </PriceComponent>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 450, color: "#162A26", textTransform: "uppercase" }} marginTop={4}>
              Material LCA data
            </Typography>
            <StyledContainer>
              <StyledContent>
                <div>
                  <div style={{ fontSize: '10.1px' }}>Total CO2 Emissions</div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                    <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                    <p style={{ paddingLeft: '4px' }}>7676</p>
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: '10.1px' }}>Water saved</div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                    <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                    <p style={{ paddingLeft: '4px' }}>7676</p>
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: '10.1px' }}>Chemicals & dyes avoided</div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                    <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                    <p style={{ paddingLeft: '4px' }}>7676</p>
                  </div>
                </div>
              </StyledContent>
              <UnlockOverlay>

                <ButtonComponentMain>
                  <img src={UnlockIcon} />
                  Coming soon
                </ButtonComponentMain>
              </UnlockOverlay>
            </StyledContainer>
          </Grid>
          <ButtonContainer>
            <Button data-test-id="order-sample-test-id" className='button_first' variant="text">Order a sample</Button>
            <Button variant="text" style={{ marginLeft: "5px" }} className='button_first'>Add to basket</Button>
            <Button style={{ marginLeft: "5px" }} className='button_last' variant="text">
              Buy Now
            </Button>
          </ButtonContainer>
        </Grid>
      </Grid>
    )
  }

  renderProductDetail = () => {
    return (
      <ProductDetailContainer container spacing={4} marginTop="8px">
           <Grid item xs={12} sm={12} md={6}>
        <ProductDetailsSection>
    <Typography style={{ color: "#292524", fontWeight: 420, fontSize: "20px" }} gutterBottom>
    Product Details
  </Typography>
            <DetailInnerContainer container spacing={2} style={{ marginTop: "13px" }}>
  <Grid container item xs={12} sm={6} spacing={2}>
    <Grid item xs={6}>
      <DetailItem>Composition:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        100% Organic Cotton
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <DetailItem>Type of Fibre:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        Cotton
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <DetailItem>Fabric Formation:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        Woven
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <DetailItem>Width:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        150 cm
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <DetailItem>Weight:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        200 g/m²
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <DetailItem>Color:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        Navy Blue
      </Typography>
    </Grid>
  </Grid>

  <Grid item xs={12} sm={0.5}>
    <div style={{ borderLeft: "1px solid #E7E5E4", height: "100%" }}></div>
  </Grid>

  <Grid container item xs={12} sm={5.5} spacing={2}>
    <Grid item xs={6}>
      <DetailItem>Stretch:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        Medium
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <DetailItem>Dyed or Non-Dyed:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        Dyed with Natural Dyes
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <DetailItem>Care Instruction:</DetailItem>
    </Grid>
    <Grid item xs={6}>
      <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
        Machine wash cold, tumble dry low, do not bleach
      </Typography>
    </Grid>
  </Grid>
            </DetailInnerContainer>
</ProductDetailsSection>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DescriptionSection>
            <DescriptionFirstComponent>
            <Typography style={{ color: "#292524", fontWeight: 420, fontSize: "20px" }} gutterBottom>
              Description
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524", marginTop: "13px" }} paragraph>
              Lion Brand 24/7 Cotton Ready To Dye Hank 3Pk
              <br />
              {configJSON.DescriptionSecondText}
            </Typography>
            </DescriptionFirstComponent>
              <div>
            <Typography style={{ color: "#292524", fontWeight: 420, fontSize: "20px" }} gutterBottom>
              Logistical Information
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
  <div style={{ display: "flex", alignItems: "center" }}>
    <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", marginRight: "5px" }}>
      Location:
    </Typography>
    <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
      Berlin, Germany
    </Typography>
  </div>

  <div style={{ display: "flex", alignItems: "center" }}>
    <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", marginRight: "5px" }}>
      Lead Time: <span style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
      This product should be delivered to you in{' '}
      <span style={{ color: '#2D6A4D', fontWeight: 'bold' }}>7 working days</span>.
    </span>
    </Typography>
              </div>
  </div>
</div>
          </DescriptionSection>
        </Grid>
        <Grid item xs={12}>
          <Typography>Certification</Typography>
          <CertificationsSection>
            <div>
              <CertificationImage src={CertificationFirst} />
              <p style={{ paddingLeft: "7px", color: "#292524", fontWeight: 420, fontSize: "14px" }}>Gots</p>
            </div>

            <div>
              <CertificationImage src={CertificationSecond} />
              <p style={{ color: "#292524", fontWeight: 420, fontSize: "14px" }}>FairTrade</p>
            </div>
            <div>
              <CertificationImage src={CertificationThird} />
              <p style={{ color: "#292524", fontWeight: 420, fontSize: "14px" }}>OEKO-TEX</p>
            </div>

          </CertificationsSection>
        </Grid>
      </ProductDetailContainer>
    )
  }

  renderProductInsipiration = () => {
    return (
      <>
        <Wrapper>
          <Typography style={{ fontSize: "24px", fontWeight: 420, color: "#292524", textTransform: "uppercase" }} align="center" gutterBottom>
            Inspirations
          </Typography>
          <Grid container spacing={{ xs: 0, sm: 0, md: 4 }}>
            <Grid item xs={2} sm={3} md={4} lg={4}>
              <ImageContainerOne>
                <img
                  style={{ height: "305px" }}
                  src={InsFist}
                  alt="Inspiration 1"
                />
              </ImageContainerOne>
      </Grid>
            <Grid item xs={8} sm={6} md={4} lg={4}>
              <TextBox>
                <Typography style={{ textTransform: "uppercase", fontSize: "20px", fontWeight: 300, fontFamily: "garamond-premier-pro !important", }} gutterBottom>
                  Jacket Revamp
                </Typography>
                <DescriptionComponent style={{ fontSize: "12px", fontWeight: "390", color: "#292524" }}>
                  {configJSON.InspirationText}
                </DescriptionComponent>
              </TextBox>
            </Grid>
            <Grid item xs={2} sm={3} md={4} lg={4}>
              <ImageContainerOne>
                <img
                  style={{ height: "305px" }}
                  src={InsSecond}
                  alt="Inspiration 2"
                />
              </ImageContainerOne>
            </Grid>
          </Grid>
        </Wrapper>
        <div>
          <Typography style={{ marginTop: "28px", fontSize: "24px", fontWeight: 420, color: "#292524", textTransform: "uppercase" }} align="center">
            Similar products
          </Typography>
          <MainContainer>
            <GridContainerMain container spacing={3} justifyContent="center" style={{ flexWrap: 'nowrap' }}>
              {items.map((item: Item, index: number) => (
                <Grid item xs={12} sm={4} md={2.4} lg={2.4} key={index}>
                  <CardMainComponent style={{ padding: "5px", cursor: "pointer" }}>
                    <div style={{ position: 'relative' }} data-test-id="grid-navigate-id" onClick={this.handleProductDescription}>
                      <CardMediaComponent
                        image={item.attributes?.pictures[0]?.url ?? productImg}
                      />
                      <IconButtonComponent style={{ position: 'absolute', top: 1, right: 0, zIndex: 1, color: 'white' }}>
                        <FavoriteBorderIcon />
                      </IconButtonComponent>
                    </div>
                    <CardContentComponent>
                      <Typography noWrap style={{ fontWeight: "420", fontSize: '16px', marginTop: "10px", fontFamily: "brandon-grotesque, sans-serif", color: "#162A26" }} gutterBottom>
                        {item.title}
                      </Typography>
                      <ContainerMain>
                        <ItemNew>
                          <PriceTypography>PRICE</PriceTypography>
                          <Value>
                            £0.45 /kg
                          </Value>
                        </ItemNew>
                        <ItemNew>
                          <PriceTypography>AVAILABLE</PriceTypography>
                          <Value>
                            150,000.00 kg
                          </Value>
                        </ItemNew>
                      </ContainerMain>
                      <CardContentRowLast>
                        <div style={{ height: "14px", width: "14px", backgroundColor: "#D84539" }} />
                      </CardContentRowLast>
                    </CardContentComponent>
                  </CardMainComponent>
                </Grid>
              ))}
            </GridContainerMain>
          </MainContainer>
        </div>
      </>

    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationWrapper {...this.props}>
        <ContainerRootMain>
          {this.renderThumbnailPart()}
          <div style={{
              height: "1px",
              backgroundColor: "#E7E5E4",
              width: "100%",
              marginTop: "49px",
              borderRadius: "5px"
          }}></div>
          {this.renderProductDetail()}
          {this.renderProductInsipiration()}
        </ContainerRootMain>
      </NavigationWrapper>



      // Customizable Area End
    );
  }
}

// Customizable Area Start


const ContainerRootMain = styled(Container)({
  flexGrow: 1,
  padding: "24px",
  '@media (min-width: 1200px)': {
    '&.MuiContainer-root': {
      maxWidth: '1340px',
    },
  },
});

const ThumbnailContainer = styled(Grid)({
  position: 'relative',
  paddingRight: "46px",
  '@media (max-width: 360px)': {
    paddingRight: "0px !important"
  },
  '@media (max-width: 420px)': {
    paddingRight: "0px !important"
  },
  '@media (max-width: 820px)': {
    paddingRight: "0px !important"
  },
})


const ProductImage = styled(CardMedia)({
  paddingTop: '100%',
  backgroundSize: 'contain',
  backgroundColor: '#f5f5f5',
});

const FavoriteCompomnent = styled('div')({
  position: 'absolute',
  top: '7px',
  right: '51px',
  color: 'white',

  '@media (max-width: 420px)': {
    right: "17px !important",
  },
  '@media (max-width: 360px)': {
    right: "17px !important",
  },
  '@media (max-width: 1476px)': {
    right: "51px",
    top: "8px"
  },
  '@media (max-width: 820px)': {
    right: "15px !important"
  },
})

const ZoomButton = styled('div')({
  position: 'absolute',
  top: '81% !important',
  right: '55px',
  color: 'white',
  width: '33px',
  height: '33px',
  background: '#FFFFFF',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  '@media (max-width: 420px)': {
    display: "none !important"

  },
    '@media only screen and (max-width: 810px)': {
      display:"none !important"
  },

  '@media (max-width: 360px)': {
    '&.MuiButtonBase-root.MuiIconButton-root': {
      display: "none"
    },
  },
  
});

const PriceTag = styled(Typography)({
  textDecoration: 'line-through',
  marginRight: '10px',
  color: "#292524",
  fontSize: "20px",
  fontWeight: 420

});

const DiscountedPrice = styled(Typography)({
  fontSize: "40px",
  fontWeight: 420,
  color: "#292524"
});

const BoxComponent = styled(Box)({
  padding: "5px 10px 14px 19px",
  borderRadius: "24px",
  opacity: "0px",
  border: "1px solid #528A6F",
  width: "60px",
  height: "14px",
  background: "#F4FFF3"
})

const SmallImagesContainer = styled(Grid)({
  marginTop: "10px",
});

const PriceComponent = styled(Grid)({
  display: "flex",
  width: "343px",
  height: "51px",
  padding: "0px 20px 0px 20px",
  gap: "10px",
  border: "1px solid #D6D3D1",
  opacity: "0px",
  marginTop: "10px",
  '@media (max-width: 360px)': {
    width: "100% !important"
  },
})

const ButtonComponentMain = styled(Button)({
  width: "168px !important",
  height: "40px",
  gap: "8px",
  opacity: "0px",
  border: "1.5px solid #B7862B",
  borderRadius: "8px",
  color: "#B7862B",
  fontSize: "14px",
  fontWeight: 420,
  background: "#FFFFFF",

  '@media (max-width: 360px)': {
    width: "155px !important"
  },
  '@media (max-width: 420px)': {
    width: "155px !important"
  },
})

const SmallImage = styled(Grid)({
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "80px",
  width: "80px",
  margin: "0px 21px 0px 0px",
  cursor: "pointer",
  '@media (max-width: 360px)': {
    width: "83px !important"
  },
  '@media (max-width: 420px)': {
    width: "83px !important"
  },
});

const MeterWrapper = styled(Grid)({
  width: '342px',
  border: '1px solid #ccc',
  display: 'flex',
  alignItems: 'center',
  height: "50px",
  justifyContent: "space-between",

});


const StyledIconButton = styled(IconButton)({
  padding: '8px',
  margin: '0 5px',
  '& svg': {
    fontSize: '1.5rem',
    color: '#333',
  },
});


const MeterInput = styled('div')({
  width: '50px',
  textAlign: 'center',
  fontSize: '21PX',
  fontWeight: '420',
  color: '#162A26',

});



const UnitBox = styled('div')({
  backgroundColor: '#f0fdf4',
  padding: '10px',
  borderRadius: '4px',
  fontSize: '1.1rem',
  fontWeight: '500',
  color: '#162A26',
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  display: "flex",
  border: "1px solid #E0EEDF"
});

const MeterComponent = styled(Grid)({
  width: "84px",
  height: "48px",
})


const QuantityText = styled('div')({
  fontSize: "16px",
  fontWeight: 450,
  marginLeft: "16px",
  '@media (max-width: 420px)': {
    marginLeft: "2px",
    marginTop: "10px"
  },
  '@media (max-width: 360px)': {
    width: "320px !important",
    marginLeft: "2px",
    marginTop: "10px"
  },
});

const ButtonContainer = styled(Box)({
  marginTop: "43px",
  display: "flex",
  '@media (max-width: 420px)': {
    flexWrap: "wrap"
  },
  '@media (max-width: 360px)': {
    flexWrap: "wrap"
  },
  " & .button_first": {
    width: "168px !important",
    height: "40px",
    gap: "8px",
    borderRadius: "36px",
    opacity: "0px",
    border: "1.5px solid #B7862B",
    color: "#B7862B",
    fontSize: "14px",
    fontWeight: 420,
    '@media (max-width: 420px)': {
      width: "100% !important",
      marginTop: "10px",
      marginLeft: "5px"
    },
    '@media (max-width: 360px)': {
      width: "100% !important",
      marginTop: "10px",
      marginLeft: "5px"
    },

  },
  " & .button_last": {
    width: "168px !important",
    height: "40px",
    gap: "8px",
    borderRadius: "36px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: 420,
    background: "#1C1917",
    color: "#FFF",
    '@media (max-width: 420px)': {
      width: "100% !important",
      marginTop: "10px",
      marginLeft: "0px"
    },
    '@media (max-width: 360px)': {
      width: "100% !important",
      marginTop: "10px",
      marginLeft: "0px"
    },
    '@media (max-width: 768px)': {
      width: "368px !important"
    },
    '@media only screen and (max-width: 820px)': {
     width:"412px !important"
    },
    "&:hover": {
      background: "#1C1917",
    },
  },
});

const StyledContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  width: "65%",
  flexDirection: 'column',
  overflow: 'hidden',
  background: "#F5F5F480",
  height: "95px",
  '@media (max-width: 420px)': {
    width: "100% !important"
  },
  '@media (max-width: 360px)': {
    width: "100% !important"
  },
});

const StyledContent = styled('div')({
  maxHeight: '100vh',
  overflowY: 'auto', display: "flex",
  justifyContent: "space-between",
  filter: "blur(4px)",
  transition: "filter 0.3s ease",

  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    borderRadius: '5px',
  },

  '@media (max-width: 768px)': {
    maxHeight: '80vh',
  },
});

const UnlockOverlay = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  '@media (max-width: 360px)': {
    width: "155px !important"
  },
  '@media (max-width: 420px)': {
    width: "155px !important"
  },
})

const ProductDetailContainer = styled(Grid)({
  '@media (min-width: 800px) and (max-width: 820px)': {
   flexDirection: "column !important"
  }


})

const DetailInnerContainer = styled(Grid)({
  '@media (max-width: 820px)': {
    flexWrap: "nowrap"
  },
})


const ProductDetailsSection = styled(Box)({
  padding: "0px"
})


const DetailItem = styled(Typography)({
  fontWeight: 450,
  fontSize: "14px",
  color: "#292524"
})


const DescriptionSection = styled(Box)({
  padding: "0px",
  '@media (min-width: 800px) and (max-width: 820px)': {
    display: "flex"
  }
});

const CertificationsSection = styled(Box)({
  padding: "20px",
  display: "flex",
  justifyContent: "space-between",
  width: "25%",
  '@media (max-width: 360px)': {
    width: "65% !important",
  },
  '@media (max-width: 420px)': {
    width: "65% !important",
  },

})

const CertificationImage = styled('img')({
  height: "50px",
  width: "auto"
})

const Wrapper = styled('div')({
  maxWidth: "1200px",
  background: "#FAFAF9",
  padding: "29px 63px"
})

const ImageContainerOne = styled('div')({
  width: "100%",
  height: "312px",
  overflow: "hidden",

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }
})

const TextBox = styled(Box)({
  padding: '16px',
  background: '#F5F5F4',
  height: '273px',
});

const DescriptionComponent = styled('p')({
  '@media only screen and (max-width: 420px)': {
    fontSize: '6px !important',
    fontWeight: 390,
    lineHeight: '11px',
  },
});

const DescriptionFirstComponent = styled('div')({
  '@media only screen and (max-width: 820px)': {
     width:"50% !important"
  },
})


const items: Item[] = new Array(5).fill({
  title: 'Sheer Lustre Faille',
  image: 'https://images.unsplash.com/photo-1691096671143-d05c501339bc?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  price: '£0.45 /kg',
  available: '150,000.00 kg'
});

const CardMainComponent = styled(Card)({
  boxShadow: "rgba(0, 0, 0, 0.9) 0px 0px 1px -1px, rgba(0, 0, 0, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important"
})


const CardMediaComponent = styled(CardMedia)({
  height: "140px"
});

const IconButtonComponent = styled(IconButton)({
  "& .css-i4bv87-MuiSvgIcon-root": {
    width: "18px",
    height: "16px"
  }
});

const CardContentComponent = styled("div")({
  padding: "4px",
  "& .css-46bh2p-MuiCardContent-root:last-child": {
    paddingBottom: "5px"
  }
});

const ContainerMain = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});



const ItemNew = styled('div')({
  textAlign: 'center',
});

const Value = styled(Typography)({
  fontSize: '14px',
  color: "#162A26",
  fontFamily: "brandon-grotesque, sans-serif",
  fontWeight: 420,
  textAlign: "left"
});

const PriceTypography = styled(Typography)({
  fontWeight: 400,
  fontFamily: "garamond-premier-pro !important",
  color: "#2D6A4D",
  fontSize: '11px',
  textAlign: "left"
});


const CardContentRowLast = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "19px"
});

const MainContainer = styled('div')({
  display: "flex",
  '@media only screen and (max-width: 420px)': {
    overflowX: 'scroll',
    display: "flex",

  }
});

const GridContainerMain = styled(Grid)({
  display: 'flex',
  overflowX: 'auto' as 'auto',
  whiteSpace: 'nowrap' as 'nowrap',
  width: '100%',
  padding: '16px',
  boxSizing: 'border-box' as 'border-box',
})

// Customizable Area End
