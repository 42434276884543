import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { AccountAttributes, AccountResponse, ComapnyDetailResponse, ErrorResponse } from "../../../blocks/utilities/src/typeInterface";
import { toast } from "react-toastify";
import { apiCall, convertFormData, getDiffs, handleApiError, handleExpiredToken } from "../../../blocks/utilities/src/CommonFunctions";
import { countryList, roleList } from "../../../blocks/utilities/src/constants";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openModal: boolean;

  userInfo: AccountAttributes;
  userId: string | number;
  profilePicture: string;
  defaultProfilePicture: string;
  [key: string]: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export default class AddUserModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyDetailsApi: string = "";
  addUserApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      openModal: true,

      userInfo: {
        activated: true,
        country_code: "",
        email: "",
        first_name: "",
        last_name: "",
        full_phone_number: "",
        phone_number: "",
        account_image: "",
        type: "",
        country: "",
        city: "",
        company_name: "",
        job_title: "",
        created_at: "",
        updated_at: "",
        device_id: "",
        unique_auth_id: "",
        user_role: "",
      },
      userId: "",
      profilePicture: "",
      defaultProfilePicture: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleExpiredToken(responseJsonData, this.props);

      if (apiCallId && responseJsonData) {
        if (apiCallId === this.getCompanyDetailsApi) {
          this.getCompanyDetailsResponse(responseJsonData);
        } else if (apiCallId === this.addUserApi) {
          this.addUserResponse(responseJsonData);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getCompanyDetails();
  }

  getCompanyDetails = async () => {
    this.getCompanyDetailsApi = await apiCall({ method: configJSON.getMethod, endPoint: configJSON.getCompanyDetailsApiEndpoint, token: true });
  }
  getCompanyDetailsResponse = (responseData: ComapnyDetailResponse) => {
    if (responseData.data.attributes) {
      const { attributes } = responseData.data
      this.setState({
        userInfo: { ...this.state.userInfo, company_name: attributes.name },
      })
    }
  }

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };
  setFormikInitialValues = () => {
    const { userInfo } = this.state;
    return {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      job_title: userInfo.job_title,
      company_name: userInfo.company_name,
      country: userInfo.country,
      city: userInfo.city,
      country_code: userInfo.country_code,
      phone_number: userInfo.phone_number,
      user_role: userInfo.user_role || roleList[0].value,
      account_image: "",
    };
  };
  shortName = () => {
    const { first_name, last_name } = this.state.userInfo;
    const firstInitial = first_name ? first_name.trim()[0] : "";
    const lastInitial = last_name ? last_name.trim()[0] : "";
    return (firstInitial + lastInitial).toUpperCase();
  };

  handleProfileChange = (
    eventItem: { target: { files: File[] } },
    setFieldValue: {
      (
        field: string,
        value: string | string[] | undefined | File,
        shouldValidate?: boolean | undefined
      ): void;
      (arg0: string, arg1: string): void;
    }
  ) => {
    const file = eventItem.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "image/jpg"
      ) {
        setFieldValue("account_image", file);
        this.setState({
          profilePicture: URL.createObjectURL(file),
        });
      } else {
        toast.error(configJSON.invalidFileError);
      }
    }
  };

  handleClearProfileImg = (setFieldValue: {
    (field: string, value: File, shouldValidate?: boolean | undefined): void;
    (arg0: string, arg1: null): void;
  }) => {
    this.setState({
      profilePicture: "",
    });
    setFieldValue("account_image", null);
  };
  handleCountryChange = (
    setFieldValue: (field: string, value: string | number) => void,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedOption = event.target.value;
    const country_code = countryList.find(
      (country: { value: string }) => country.value === selectedOption
    )?.country_code;
    setFieldValue("country", selectedOption);
    setFieldValue("country_code", country_code ?? "");
  };

  onResetForm = (handleReset: () => void) => {
    this.setState({
      profilePicture: this.state.defaultProfilePicture,
    });
    handleReset();
  };

  checkFormDisabled = (values: Partial<AccountAttributes>) => {
    const {
      account_image,
      first_name,
      last_name,
      email,
      job_title,
      company_name,
      phone_number,
      country,
      city,
      country_code,
    } = this.state.userInfo;

    const orriginalData = {
      first_name,
      last_name,
      email,
      job_title,
      company_name,
      country,
      city,
      country_code,
      phone_number,
      account_image,
    };

    const attrs = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      job_title: values.job_title,
      company_name: values.company_name,
      country: values.country,
      city: values.city,
      country_code: values.country_code,
      phone_number: values.phone_number,
      account_image: values.account_image,
    };
    const userData = getDiffs(orriginalData, attrs);
    return !Object.keys(userData).length;
  };

  addUser = async (values: Partial<AccountAttributes>) => {

    const { first_name, last_name, email, job_title, phone_number, country, city, country_code, user_role, account_image } = values;

    const userData = {
      first_name,
      last_name,
      email,
      job_title,
      country,
      city,
      country_code,
      phone_number,
      user_role,
      ...(account_image ? {account_image} : {})
    };
    
    this.addUserApi = await apiCall({
      method: configJSON.postMethod,
      body: convertFormData(userData),
      endPoint: `${configJSON.addUserEndpoint}`,
      token: true,
    });
  }
  addUserResponse = async (responseData: AccountResponse & ErrorResponse) => {
    if (responseData?.data?.attributes) {
      this.handleCloseModal()
      toast.success(configJSON.formSuccessMsg)
    } else {
      handleApiError(responseData.errors);
    }
  }
  // Customizable Area End
}
