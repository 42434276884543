
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_productdescription/productdescriptions";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
exports.DescriptionSecondText="24/7 Cotton® is a worsted weight, mercerized 100% natural fiber yarn. The mercerization process gives the yarn incredible sheen which will last through multiple wash cycles. Dye it into your favorite color and then stitch up fashion   garments, home decor, bath and kitchen accessories, and items for babies and children."
exports.InspirationText = "Instead of tossing out that old jacket, give it a stylish makeover. Add some flair with fabric scraps or patches in vibrant colors and patterns. You can also repurpose old scarves, belts, or vintage buttons to create a one-of-a-kind accessor that transforms your jacket into a fashion statement. This creative approach not only breathes new life into your wardrobe but also reduces fashion waste, showing that sustainability can be chic and environmentally conscious."
// Customizable Area End
