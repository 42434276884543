Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.apiEndPointAddDetail =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.errorCountryCode = "Please select country code";
exports.errorTitle = "Error";
exports.apiSendOtpContentType = "application/json";
exports.apiSendOtpEndPoint =
  "account/accounts/send_otp";

exports.errorMobileNotValid = "Phone number is not valid.";
exports.apiSendOtpMethod = "POST";

exports.placeHolderMobile = "Mobile";
exports.btnTxtSendOtp = "Send me otp";
exports.placeHolderSelectCountry = "Select Country";

exports.bodyText = "Value proposition: why users should sign up.";

exports.labelInfo =
  "We will send a 4 digit OTP to the number below.If the number is incorrect, Change it and resent OTP";

exports.modalTitle = "Add new user";
exports.first_name_text_profile = "First name"
exports.first_name_placeholder = "User name"

exports.last_name_text_profile = "Surname"
exports.last_name_placeholder = "User surname"

exports.email_text_profile = "Work email"
exports.email_placeholder = "User working email eg. johndoe@companyname.com"

exports.job_title_text_profile = "Job title"
exports.job_title_placeholder = "User job title"

exports.company_name_text_profile = "Company name"
exports.company_name_placeholder = "Company-name"

exports.country_text_profile = "Country"
exports.country_placeholder = "Select country"

exports.city_text_profile = "City"
exports.city_placeholder = "Select city"

exports.phone_number_text_profile = "Phone number"
exports.phone_number_placeholder = "XXX XXX XXXX"

exports.role_text_profile = "Role"
exports.role_placeholder = "Select role"

exports.status_text_profile = "Status"
exports.status_placeholder = "Select status"

exports.getMethod = "GET";
exports.postMethod = "POST";
exports.getCompanyDetailsApiEndpoint = "bx_block_invoice/companies/get_company";
exports.formSuccessMsg = "User has been added successfully.";
exports.addUserEndpoint = "account_block/reporting_users/";

// Customizable Area End